import { useState, useEffect, useReducer } from "react";

import Select from "../UI/Select/Select";
import PhoneInput from "../UI/Input/PhoneInput";

import useInput from "../../hooks/useInput";
import classes from "./EnrollForm.module.scss";

const phoneTypeOptions = [
  {
    value: "0",
    label: "Select...",
    isDisabled: true,
  },
  {
    value: "home",
    label: "Home",
    isDisabled: false,
  },
  {
    value: "work",
    label: "Work",
    isDisabled: false,
  },
  {
    value: "cell",
    label: "Cell",
    isDisabled: false,
  },
  {
    value: "remove",
    label: "Remove",
    isDisabled: false,
  },
];

const phoneTypeReducer = (state, action) => {
  if (action.type === "CHANGE") {
    return {
      value: action.payload,
      hasError: action.payload === "",
    };
  }

  if (action.type === "SET_VALID") {
    return {
      value: state.value,
      hasError: action.payload,
    };
  }

  return {
    value: "",
    hasError: false,
  };
};

const PhoneRow = ({ phoneData, setPhones, phones, shouldDisplayError, index, disableValidation }) => {
  const { id, phone_number, phone_type } = phoneData;
  
  let [phonetypes, setPhoneTypes] = useState([]);
  let [hasProceedingFieldSelected, selected] = useState(false);

  useEffect(() => {
    shouldDisplayError.current[index] = selected;
  }, [selected, index, shouldDisplayError])

  const [phoneTypeState, dispatchPhoneType] = useReducer(phoneTypeReducer, {
    value: phone_type,
    hasError: false,
  });

  const { value: phoneTypeValue } = phoneTypeState;


  const {
    value: number,
    isTouched: numberTouched,
    isValid: numberIsValid,
    hasError: numberHasError,
    inputChangeHandler: numberChangeHandler,
    inputBlurHandler: numberBlurHandler,
  } = useInput((value) => {
    return disableValidation ? value : value.replace(/[^\d]/g, "").length >= 10;
  }, phone_number);


  const errorMessageProvider = () => {
    if(number.replace(/[^\d]/g, "").length !== 10) {
      return 'Enter a valid phone number'
    }

    return "Enter valid data"
  }
  

  useEffect(() => {
    if(phoneTypeValue === "remove") {
      shouldDisplayError.current[index] = false;
      setPhones((prevState) => {
        return prevState.filter((item) => item.id !== id);
      });
    }
    let tempPhoneTypes = [];
    for(let i = 0; i < phones.length; i++) {
      if(phones[i].id === id) {
        continue;
      }
      tempPhoneTypes.push(
        phones[i].phone_type,
      );
    }
    setPhoneTypes(tempPhoneTypes);

    setPhones((prevState) => {

      return prevState.map((item) => {
        if (item.id === id) {
          return {
            id: item.id,
            phone_number: number,
            phone_type: phoneTypeValue,
            phone_number_hasError: !numberIsValid,
            phone_type_hasError:
              !phoneTypeValue || phoneTypeValue === "0", // todo
            phone_number_setValid: numberBlurHandler,
            phone_type_setValid: setPhoneTypeValid,
          };
        } else {
          return {
            id: item.id,
            phone_number: item.phone_number,
            phone_type: item.phone_type,
            phone_number_hasError: item.phone_number_hasError,
            phone_type_hasError: item.phone_type_hasError,
            phone_number_setValid: item.phone_number_setValid,
            phone_type_setValid: item.phone_type_setValid,
          };
        }
      });
    });

    // eslint-disable-next-line
  }, [id, number, phoneTypeValue]);

  const setPhoneTypeValid = () => {
    dispatchPhoneType({ type: "SET_VALID", payload: phonetypes.includes(phoneTypeValue) })
  }

  const changeSelectHandler = (event) => {
    setPhoneTypeValid();
    dispatchPhoneType({ type: "CHANGE", payload: event.target.value });
  };

  const getOptions = () => {
    if (phones.length === 1) {
      return phoneTypeOptions.map((item) => {
        if (item.value === "remove") {
          return {
            value: item.value,
            label: item.label,
            isDisabled: true,
          };
        }
        return item;
      });
    }
    return phoneTypeOptions;
  }

  const phoneTypeHasError = () => {
    const duplicate = phonetypes.includes(phoneTypeValue);
    if(duplicate) {
      return true;
    }else {
      // setUpdatedOther(false);
    }

    return phoneTypeValue === "0" || duplicate;
  }

  return (
    <div className={classes.Row}>
      <div className={classes.Col__100}>
        <PhoneInput
          type="phone"
          label="Phone Number*"
          placeholder="(111) 222-333"
          value={number}
          touched={numberTouched}
          hasError={numberHasError}
          errorMessageProvider={errorMessageProvider}
          onChange={numberChangeHandler}
          onBlur={numberBlurHandler}
          disableValidation={disableValidation}
        />
      </div>

      <div className={classes.Col__100}>
        <Select
          label="Phone Type*"
          options={getOptions()}
          value={phoneTypeState.value}
          hasError={phoneTypeState.hasError || (phoneTypeHasError() && hasProceedingFieldSelected)}
          onChange={changeSelectHandler}
          errorMessageProvider={() => {
            if(phoneTypeValue === 0) {
              return "Select a phone type"
            }
            console.log(phoneTypeValue)
            if(phoneTypeValue === '0') {
              return 'Select a phone type'
            }
            if(phonetypes.includes(phoneTypeValue)) {
        
              return "Duplicate type not permitted"
            }

            return 'Enter Valid Data'
          }}
          dont_sort
        />
      </div>
    </div>
  );
};

export default PhoneRow;
